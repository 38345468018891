// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// require("bootstrap")
// window.FontAwesomeConfig = { searchPseudoElements: true }
// require("@fortawesome/fontawesome-free/js/all")

require('bootstrap-datepicker/dist/js/bootstrap-datepicker.min')
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min"
// require("chosen-js/chosen.jquery.min.js")

import '../stylesheets/application'
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("./home");
require('./custom');
require('./common');

// document.addEventListener("turbolinks:load", () => {
//   $(function() {
//     $('[data-toggle="popover"]').popover()
//     $('[data-toggle="dropdown"]').dropdown()
//     $('[data-toggle="tooltip"]').tooltip({
//       'html': true
//     })
//     $('.toast').toast({
//       autohide: false
//     })
//     $('.toast').toast('show')
//   })
//
//   // $('.form-control-chosen').chosen();
//
// })


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)