$(document).ready(function () {
  $(document).on('turbolinks:load', function () {

  });
});

$(function () {
  var flashCallback;
  flashCallback = function () {
    return $(".alert.auto-hide").slideUp('slow');
  };
  $(".alert.auto-hide").bind('click', function (ev) {
    return $(".alert.auto-hide").slideUp('slow');
  });
  setTimeout(flashCallback, 48000);
  flashCallback
});
